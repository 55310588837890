<template>
  <div>
    <v-col
      cols="12"
      class=" my-2 "
    >
      <SantralSelect @selectedsantral="selectedSantralFunc" />
    </v-col>
    <v-col
      cols="12"
      class="px-3 py-1"
    >
      <InstantDataGauge :selectedsantral="santralmodel" />
    </v-col>

    <v-row class="mx-2 py-2">
      <v-col
        cols="12"
        sm="12"
        md="12"
        lg="9"
        xl="9"
        class="px-1 py-2"
      >
        <SantralStats :selectedsantral="santralmodel" />
      </v-col>

      <v-col
        cols="12"
        md="12"
        sm="12"
        lg="3"
        xl="3"
        class="px-0 py-2   "
      >
        <Weather />
      </v-col>
    </v-row>

    <v-col>
      <DailyMinuteData :selectedsantral="santralmodel" />
    </v-col>
  </div>
</template>

<script>
import SantralSelect from '@/views/components/Santrals/SantralSelect.vue';
import InstantDataGauge from '@/views/components/Santrals/InstantDataGauge.vue';
import SantralStats from '@/views/components/Santrals/SantralStats.vue';
import DailyMinuteData from '@/views/components/Santrals/DailyMinuteData.vue';
import Weather from '@/views/components/Dashboard/Weather.vue';

export default {
  components: {
    SantralSelect,
    InstantDataGauge,
    SantralStats,
    DailyMinuteData,
    Weather,
  },
  data: () => ({
    santralmodel: 'null',
  }),
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    selectedSantralFunc(item) {
      this.santralmodel = item;
    },
  },
};
</script>

<style>
.v-card__subtitle {
  margin-bottom: -15px !important;
}
</style>
