<template>
  <div>
    <v-row class="mr-1 mt-1">
      <v-col
        v-for="(measure, key) in measureList"
        :key="key"
        cols="12"
        sm="6"
        md="6"
        lg="3"
        xl="3"
        class="pr-0 mt-0 pt-0 m-0"
      >
        <v-card>
          <div
            :id="measure.value + 'santralgeneral-santral-live-data'"
            class="text-center"
            class-name="widget-content "
            style="width: 350px; height: 200px; text-align: center"
          />
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import * as Santral from '@/api/Dashboard/Santral';
import i18n from '@/locale';
import moment from 'moment';
import * as echarts from 'echarts';

export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['selectedsantral'],
  data: () => ({
    chartData: {},
    sensorList: {},
  }),
  computed: {
    measureList() {
      return [
        {
          value: 'PerformanceRatio',
          text: i18n.t('PerformanceRatio'),
          type: 'float',
          unit: '%',
        },
        {
          value: 'IstantaneousPower',
          text: i18n.t('IstantaneousPower'),
          type: 'float',
          unit: 'Kw',
        },
        {
          value: 'Irr',
          text: i18n.t('Irradiation'),
          type: 'float',
          unit: 'mv',
        },
        {
          value: 'Temp',
          text: i18n.t('Temperature'),
          type: 'float',
          unit: '°C',
        },
      ];
    },
  },
  watch: {
    selectedsantral() {
      if (this.selectedsantral !== null && this.selectedsantral !== undefined) {
        this.getSantralLiveData();
      }
    },
  },
  created() {},
  mounted() {
    setInterval(this.getSantralLiveData, 300000);

    let k = 0;
    // eslint-disable-next-line no-use-before-define
    const myVar2 = setInterval(myTimer2, 1000);

    const self = this;
    function myTimer2() {
      k += 1;

      const { santralSensorData } = self.$store.getters;

      if (k > 30) {
        clearInterval(myVar2);
      }

      if (
        Object.keys(santralSensorData).length > 0
        && santralSensorData !== undefined
      ) {
        clearInterval(myVar2);

        self.sensorList = santralSensorData;
        self.getSantralLiveData();
      }
    }
  },

  methods: {
    getSantralLiveData() {
      const formdata = {};

      formdata.santral = this.setStatsMeasure();
      formdata.sensor = {};
      if (Object.keys(this.sensorList).length > 0) {
        formdata.sensor = this.setSensorMeasure();
      }

      const minuteData = Santral.default.getSantralLiveData(
        formdata,
        this.measureList,
      );
      const echartdom = {};
      const myChart = {};
      minuteData.then((res) => {
        this.chartData = res;
        this.measureList.forEach((measure) => {
          const id = `${measure.value}santralgeneral-santral-live-data`;
          echartdom[id] = document.getElementById(id);
          myChart[id] = echarts.init(echartdom[id]);
          myChart[id].setOption(res[measure.value]);
        });
        this.$store.dispatch('setGeneralUpdateTime', res.updateTime);
      });
    },

    setSensorMeasure() {
      const sensorList = Object.keys(this.sensorList[this.selectedsantral]);
      const { sensorMeasures } = this.$store.getters;
      const measures = [];
      Object.keys(sensorMeasures).forEach((measure) => {
        measures.push(measure.value);
      });

      const date = moment().format('YYYY-MM-DD');
      return {
        condiniton: {
          santral: this.selectedsantral,
          skip: 0,
          limit: 1,
          orderBys: [{ fieldName: 'datetimestamp', sortOrder: 'desc' }],
          count: '0',
          date,
          sensor: sensorList,
          measures,
        },
      };
    },

    setStatsMeasure() {
      const generalStatsMeasureList = [
        {
          value: 'PerformanceRatio',
          text: i18n.t('PerformanceRatio'),
          type: 'float',
          unit: '%',
        },
        {
          value: 'IstantaneousPower',
          text: i18n.t('IstantaneousPower'),
          type: 'float',
          unit: 'Kw',
        },
      ];
      const measures = [];

      generalStatsMeasureList.forEach((item) => {
        measures.push(item.value);
      });
      const date = moment().format('YYYY-MM-DD');

      return {
        condiniton: {
          santral: this.selectedsantral,
          skip: 0,
          limit: 1,
          orderBys: [{ fieldName: 'datetimestamp', sortOrder: 'desc' }],
          count: '0',
          measures,
          date,
        },
      };
    },

    forceRerender() {
      this.renderComponent = false;
      this.$nextTick().then(() => {
        this.renderComponent = true;
      });
    },
  },
};
</script>

<style>
.v-card__subtitle {
  margin-bottom: -15px !important;
}
</style>
